.leaderboard {
    width: 100%;
    border-radius: 12px;
}

.leaderboard .headerv {
    --start: 15%;
    height: 120px;
    /* background-image: repeating-radial-gradient(circle at var(--start), transparent 0%, transparent 10%, rgba(54, 89, 219, 0.33) 10%, rgba(54, 89, 219, 0.33) 17%), linear-gradient(to right, #1097CD, #053C9B); */
    color: #fff;
    position: relative;
    border-radius: 12px 12px 0 0;
    border: 1px solid #3f4254;
    overflow: hidden;
}

.leaderboard .headerv .leaderboard__title {
    margin-top: 50px;
    margin-left: 20px;
}

.leaderboard .headerv .leaderboard__title span {
    display: block;
}

.leaderboard .headerv .leaderboard__title--top {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.leaderboard .headerv .leaderboard__title--bottom {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 3.55px;
    opacity: 0.65;
    transform: translateY(-2px);
}

.leaderboard .headerv .leaderboard__icon {
    fill: #fff;
    opacity: 0.35;
    width: 50px;
    position: absolute;
    top: 50%;
    left: var(--start);
    transform: translate(-50%, -50%);
}

.leaderboard__profiles {
    background-color: #fff;
    border-radius: 0 0 12px 12px;
    padding: 15px 15px 20px;
    display: grid;
    row-gap: 8px;
}

.leaderboard__profile {
    display: grid;
    grid-template-columns: 0.7fr 2fr 1fr 1fr;
    align-items: center;
    padding: 10px 30px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: #fff;
}

.leaderboard__profile_buyer {
    display: grid;
    grid-template-columns: 0.7fr 2fr 1fr 1fr 1fr;
    align-items: center;
    padding: 10px 30px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    /* cursor: pointer; */
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: #fff;
}

.leaderboard__profile_w_v {
    display: grid;
    grid-template-columns: 0.7fr 2fr 1fr 1fr 1fr;
    align-items: center;
    padding: 10px 30px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: #fff;
}

.leaderboard__profile:hover,
.leaderboard__profile_w_v:hover,
.leaderboard__profile_buyer:hover {
    transform: scale(1.2);
    box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
}

.leaderboard__picture {
    color: #053C9B;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    max-width: 100%;
    width: 30px;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #ebeef3, 0 0 0 22px #f3f4f6;
}

.leaderboard__name {
    color: #053C9B;
    font-weight: 600;
    font-size: 16px;
    /* letter-spacing: 0.64px; */
    margin-left: 12px;
    width: 20%;
}

.leaderboard__value {
    /* color: #35d8ac; */
    font-weight: 700;
    font-size: 18px;
    text-align: right;
    width: 20%;
}

.leaderboard__value>span {
    opacity: 0.8;
    font-weight: 600;
    font-size: 13px;
    margin-left: 3px;
}

.leaderboard {
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);
}

.leaderboard__head {
    display: flex;
}

.head_1,
.head_2,
.head_3,
.head_4 {
    width: 20%;
    font-size: 16px;
    font-weight: 600;
    margin-left: 14px;
}

.select-dropdown,
.select-dropdown * {
    font-size: 15px;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
}

.select-dropdown {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #ffc107;
    border: 1px solid #ffc107;
    border-radius: 4px;
    /* width: 35% !important; */
}

.select-dropdown select {
    font-size: 1.2rem;
    max-width: 100%;
    padding: 8px 24px 8px 10px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #160f1e;
    font-weight: 700;
}

.select-dropdown select:active,
.select-dropdown select:focus {
    outline: none;
    box-shadow: none;
}

.select-dropdown:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-top: 5px solid #aaa;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}

.select-dropdown select option {
    margin: 40px;
    background: #ffc107;
    color: #160f1e;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    font-weight: 700;
    font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
    .leaderboard .headerv .leaderboard__title {
        margin-top: 20px;
    }
    .leaderboard .headerv .leaderboard__title--top {
        font-size: 31px;
    }
    .leaderboard__name {
        font-size: 14px;
    }
    .leaderboard__picture {
        width: 20px;
        font-size: 12px;
    }
    .leaderboard__profile {
        grid-template-columns: 0.7fr 3fr 1fr 1fr;
    }
    .leaderboard__value {
        width: unset;
        font-size: 14px;
    }
    .head_1,
    .head_2,
    .head_3,
    .head_4 {
        font-size: 12px;
        margin-left: 15px;
    }
    .leaderboard .headerv {
        height: unset;
    }
}
