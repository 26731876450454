.search-container-raffle-detail {
    background-size: auto 100%;
    background: unset;
    width: 100%;
    padding-top: 50px;
    background: transparent url('../images/Rectangle 49.png') 0% 0% no-repeat padding-box;
    /* z-index: 9; */
}

.detail-page-collection-name {
    letter-spacing: 0px;
    /* color: #ffc107; */
    color: #302A47;
    font-size: 15px;
    font-weight: 600;
}

.header-tabs .nav .nav-link.active {
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
}

.tab-pane .card {
    border-radius: 0px 20px 20px 20px;
}

.search-container-raffle-detail .raffle-image {
    width: 300px;
    height: 300px;
    border-radius: 30px;
    border: 1px solid #3f4254;
}

.no-of-ticket {
    width: 20%;
}

.pointer {
    cursor: pointer;
}

.raffle-detail-label {
    text-align: left;
    color: #6b7281;
    font-weight: 500;
    font-size: 16px;
}

.raffle-detail-text {
    color: #1e4395;
    text-align: left;
    font-size: 19px;
    text-transform: capitalize;
    font-weight: 600;
}

.raffle-detail-card .card-body {
    /* padding-top: 40px; */
    background: #fff !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
}


/* .raffle-detail-card {
    min-height: 300px;
    max-height: 300px;
} */

.back-arrow {
    background: #2B2734;
    border-radius: 50px;
    box-shadow: 0px 50px 8;
    width: 50px;
    padding: 15px;
}

.back-arrow img {
    width: 18px;
    height: 14px;
}

.raffle-details-table-card .card-body {
    border-radius: 20px 20px 20px 20px;
    background: #fff !important;
}

.raffle-details-table-card table thead {
    border-right: 1px solid #eff2f5;
}

.raffle-details-table-card table thead th {
    font-size: 17px;
}

.raffle-details-table-card table thead td {
    font-size: 17px;
}

.raffle-details-table-card td:not(:last-child),
.raffle-details-table-card th:not(:last-child) {
    border-right: 1px solid #eff2f5;
}

.text-yellow {
    color: #ffc107 !important;
    font-weight: 500;
}

.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 50px;
}


/*Switch*/

.switch {
    position: relative;
    width: 430px;
    height: 50px;
    display: block;
    margin: auto;
    margin-bottom: auto;
}

.switch input {
    display: none;
}

.slider.round {
    border-radius: 50px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #181426;
    -webkit-transition: .4s;
    transition: .4s;
}


/*Moving SLider Section*/

.slider::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 53%;
    left: 0;
    bottom: 0;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50px;
}


/*Slider Text*/

.slider_text {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    letter-spacing: 0.75px;
}

.slider_text>span {
    color: #fff;
    font-size: 18px;
    width: 50%;
    display: block;
    float: left;
    -webkit-transition: .4s;
    transition: .4s;
}


/*Changes on Slide*/

input:checked+.slider::before {
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
    transform: translateX(200px);
}

input:checked+.slider .off {
    color: #514A66;
}

input:checked+.slider .on {
    color: #fff;
}

.slider .slider_text .on {
    color: #514A66;
}

.btn-raffle-detail {
    background: transparent linear-gradient(180deg, #1e4395 0%, #1e4395 100%) 0% 0% no-repeat padding-box;
    border-radius: 70px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
}

.btn-raffle-detail:hover {
    color: #fff;
}

.ticket-price-box {
    background-color: #FFFFFF !important;
    border-radius: 30px;
    border: 0.5px solid #3E3944;
    padding: 10px;
    color: #000 !important;
    margin-top: 10px;
}

.form-control.form-control-solid.ticket-price-box {
    background-color: #302A47 !important;
    text-align: center;
}

.participants-section {
    margin-top: 70px;
    margin-bottom: 10px;
}

.participant-table {
    max-height: 500px;
    overflow-y: scroll;
}


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #e4e6ef;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #1e4395 !important;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media only screen and (max-width: 1200px) {
    .raffle-detail-left {
        text-align: center;
    }
    .raffle-detail-card {
        margin-top: 20px;
        margin-bottom: 60px;
    }
    .form-control.form-control-solid {
        background-color: #fff;
        color: #000;
    }
    .ticket-price-box {
        width: 80px
    }
    .winner-participant-cond {
        margin-top: 290px !important;
    }
    .countdownHolder {
        margin: auto;
    }
}

@media only screen and (max-width: 768px) {
    .detail-grid,
    .detail-grid-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
        grid-row-gap: 20px !important;
    }
    .detail-grid-1 {
        margin-top: 15px !important;
    }
}

.winner-participant-cond {
    margin-top: unset;
}

.discord-link,
.twitter-link {
    padding-right: 10px;
}

.loader5 {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #4183D7;
    border-top: 20px solid #F5AB35;
    -webkit-animation: loader5 1.2s ease-in-out infinite alternate;
    animation: loader5 1.2s ease-in-out infinite alternate;
}

@keyframes loader5 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(720deg);
    }
}

@-webkit-keyframes loader5 {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(720deg);
    }
}

.refresh-loader {
    position: fixed;
    bottom: 100px;
    left: 10px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    background: #181426;
    padding: 15px;
    border-radius: 20px;
}

.btn-ticket-sold-out {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 70px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 15px;
    padding: 0.75rem 1rem;
    cursor: unset !important;
    border: unset;
    width: 100%;
}

.connect-wallet-r-d-page {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box !important;
}

.dao-link-raffle-detail-page {
    color: #1e4395 !important;
    text-align: left;
    font-size: 20px;
}

.w-50 {
    width: 50%;
}

.raffle-detail-card-first-sec {
    border-bottom: 1px solid;
}

.raffle-ticket-type {
    min-width: 250px;
}

.ticket-threshold-icon-warning {
    color: #ffc107;
    padding-left: 5px;
    font-size: 17px;
}

.ticket-threshold-icon-success {
    color: #00986a;
    padding-left: 5px;
    font-size: 17px;
}

.ticket-threshold-warning-text {
    font-size: 12px;
    color: #ffc107;
}

.ticket-threshold-success-text {
    font-size: 12px;
    color: #00986a;
}

.btn-raffle-force-close {
    margin-left: 5px;
}

.text-v1 {
    color: #fff;
}

.text-blue_dark {
    color: #1e4395 !important;
}

.text-table-black {
    color: #131628;
}


/*
.Toastify__toast-theme--dark {
    background: #ffc107 !important;
    color: #181c32 !important;
} */

.Toastify__toast {
    font-family: inherit !important;
    font-size: 16px !important;
    font-weight: 600;
    color: #1e4395 !important;
}


/* .Toastify__toast-icon svg {
    fill: green;
} */

.Toastify__spinner {
    border-right-color: #1e4395 !important;
}

.Toastify__toast-container {
    top: 10em !important;
}

.color-dark {
    color: #000;
}

.raffle-detail-card .raf-name {
    font-size: 1.8rem;
    font-weight: 600;
}

.raffle-detail-card .floor-price {
    font-size: 1.1rem;
    color: #302A47;
    font-weight: 600;
}

.detail-page-tab {
    font-weight: 600;
    font-size: 15px;
    color: #1e4395;
    border-radius: 25px;
    border: 1px solid;
    cursor: pointer;
}

.detail-page-tab.active {
    background: #1e4395;
    color: #fff;
}

.detail-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-row-gap: 50px;
}

.detail-grid-1 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 50px;
    margin-top: 50px;
}

.t-border {
    border-top: 1px solid rgba(229, 231, 235, 1);
}

.b-border {
    border-bottom: 1px solid rgba(229, 231, 235, 1);
}

.border-gray-lg {
    border-color: rgba(229, 231, 235, 1)!important;
}

.balance-avial {
    font-size: 16px;
    color: #fff;
    margin: auto;
    text-align: center;
    border: 1px solid #333;
    padding: 10px;
    border-radius: 30px;
    font-weight: 600;
    margin-top: 20px;
}

.winner-wrap {
    max-width: 280px;
    /* min-height: 280px; */
    /* background: #b21f66; */
    margin: 5% auto 0;
    position: relative;
    padding: 1rem 2rem 6.5rem 2rem;
    text-align: center;
    cursor: pointer;
    border: 1px solid #333;
    border-radius: 30px;
}

.winner-wrap:hover .right-ribbon {
    right: -45px;
    border-left: 20px solid #fff;
    transition: all ease .3s;
}

.winner-wrap:hover .left-ribbon {
    left: -45px;
    border-right: 20px solid #fff;
    transition: all ease .3s;
}

.winner-wrap:hover .winner-ribbon {
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.2);
    transition: all ease .3s;
}

.border {
    position: absolute;
    width: 90%;
    height: 90%;
    border: 1px solid rgba(255, 189, 105, 0.4);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.medal-box {
    width: 60px;
    height: 60px;
    background: #ffc107;
    color: #010102;
    border-radius: 50px;
    line-height: 70px;
    font-size: 2.3rem;
    margin: .8rem auto 1rem;
}

.medal-box i {
    font-family: 'Font Awesome 5 Free';
    font-size: 35px;
    color: #1d4496;
}

.winner-wrap h1 {
    color: rgba(255, 189, 105, 0.95);
    font-size: 1.4rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.winner-wrap h2 {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 400;
    /* text-transform: uppercase; */
    margin-bottom: 1.85rem;
}

.winner-ribbon {
    width: 100%;
    height: 40px;
    background: #fff;
    text-align: center;
    /* font-family: 'Bebas Neue', cursive; */
    font-size: 1.9rem;
    font-weight: 600;
    color: #1d4496;
    position: absolute;
    bottom: 45px;
    left: 0;
    /* z-index: 99; */
    box-shadow: 0 10px 15px -7px rgba(0, 0, 0, 0.2)
}

.right-ribbon {
    width: 0;
    height: 0;
    position: absolute;
    right: -35px;
    bottom: 26px;
    border-top: 20px solid #fff;
    border-bottom: 20px solid #fff;
    border-left: 10px solid #fff;
    border-right: 25px solid transparent;
    transition: all ease .3s;
}

.left-ribbon {
    width: 0;
    height: 0;
    position: absolute;
    left: -35px;
    bottom: 26px;
    border-top: 20px solid #fff;
    border-bottom: 20px solid #fff;
    border-left: 25px solid transparent;
    border-right: 10px solid #fff;
    transition: all ease .3s;
}

.raffle-detail-left .wallet-adapter-button {
    width: 100%;
}

.raffle-detail-left .btn-raffle-detail {
    width: 100%;
    margin-top: 10px;
}

.buyer-guide {
    font-size: 17px;
    /* color: #ffc107; */
    font-weight: 600;
    cursor: pointer;
}

.buyer-guide i {
    font-size: 17px;
    color: inherit;
}

.spinner-container-v2 {
    text-align: center;
}

.pre-loading-text {
    color: #fff;
    margin: auto;
    position: absolute;
    font-size: 16px;
    top: 55%;
    /* top: 45%; */
    display: inline-block;
}

.loading {
    position: fixed;
    left: 0px;
    top: 40%;
    width: 100%;
    height: 100%;
    z-index: 9999;
    text-align: center;
}

.loading-dots {
    /* position: fixed;
    left: 0px;
    top: 40%;
    width: 100%;
    height: 100%;
    z-index: 9999; */
    text-align: center;
    font-size: 2em;
    color: rgba(255, 2555, 255, 0);
    animation-name: loading-dots-animation;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes loading-dots-animation {
    0% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .6), 0em 0 0 rgba(255, 2555, 255, .2), 1em 0 0 rgba(255, 2555, 255, .2);
    }
    20% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, 1), 0em 0 0 rgba(255, 2555, 255, .6), 1em 0 0 rgba(255, 2555, 255, .2);
    }
    40% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .6), 0em 0 0 rgba(255, 2555, 255, 1), 1em 0 0 rgba(255, 2555, 255, 0.6);
    }
    60% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .2), 0em 0 0 rgba(255, 2555, 255, .6), 1em 0 0 rgba(255, 2555, 255, 1);
    }
    80% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .2), 0em 0 0 rgba(255, 2555, 255, .2), 1em 0 0 rgba(255, 2555, 255, .6);
    }
    100% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .6), 0em 0 0 rgba(255, 2555, 255, .2), 1em 0 0 rgba(255, 2555, 255, .2);
    }
}

.loading-text-1 {
    font-weight: bold;
    color: #fff;
    margin: 0.3em 0;
    font-size: 1.5em;
}

.claim-status {
    padding-top: 0.6em;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    text-align: center;
}