.search-container {
    /* background-color: #181c32; */
    background-size: auto 100%;
    /* background-image: url(../images/taieri.svg); */
    /* background: #12101000 linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    background: #2a3042;
    /* position: fixed; */
    width: 100%;
    /* z-index: 9; */
}

.header-tabs .nav .nav-link.active {
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
}

.tab-pane .card {
    border-radius: 0px 20px 20px 20px;
}

.raffle-image {
    width: 170px;
    border-radius: 20px;
}

.no-of-ticket {
    width: 20%;
}

.pointer {
    cursor: pointer;
}

.border {
    border: 1px dashed #929eab !important;
}

.raffle-home-card {
    border: unset;
}

.raffle-home-card .card-body {
    background: #2a3042;
}

.text-blue {
    color: #0095e8;
}

.dao-raffle-image {
    width: 300px;
    height: 325px;
    border-radius: 30px;
    border: 1px solid #3f4254;
}

.dao-name {
    font-size: 40px;
    color: #fff;
    font-weight: 600;
}

.dao-desc {
    font-size: 15px;
    color: #000000;
    text-align: left;
    margin-bottom: 30px;
}

.dao-head-details {
    padding: 15px;
    border: 1px solid #3f4254;
    border-radius: 20px;
}

.dao-head-details img {
    width: 40px;
}

.dao-head-details-label {
    font-size: 15px;
    color: #3f4254;
}

.dao-head-details-text {
    font-size: 20px;
    color: #1e4395;
}


/** Not Found **/

.svg-not-found {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -400px;
}

.message-box {
    height: 200px;
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: 50px;
    color: #FFF;
    font-weight: 300;
}

.message-box h1 {
    font-size: 60px;
    line-height: 46px;
    margin-bottom: 40px;
}

.buttons-con .action-link-wrap {
    margin-top: 40px;
}

.buttons-con .action-link-wrap a {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    padding: 8px 25px;
    border-radius: 50px;
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px
}

.buttons-con .action-link-wrap a:hover {
    background: #5A5C6C;
    color: #fff;
}

#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
}

#Polygon-2 {
    animation-delay: .2s;
}

#Polygon-3 {
    animation-delay: .4s;
}

#Polygon-4 {
    animation-delay: .6s;
}

#Polygon-5 {
    animation-delay: .8s;
}

@keyframes float {
    100% {
        transform: translateY(20px);
    }
}

@media (max-width: 450px) {
    .svg-not-found {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -250px;
        margin-left: -190px;
    }
    .message-box {
        top: 50%;
        left: 50%;
        margin-top: -100px;
        margin-left: -190px;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .dao-content-page {
        margin-top: 300px;
    }
}

.margin-b-300 {
    margin-bottom: 300px !important;
}