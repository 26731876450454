.header {
    left: 0px !important;
    z-index: 3;
    /* background: #fff; */
    background: transparent linear-gradient(92deg, #130F1D 0%, #160F1E 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 50px 80px #0000004D;
    border-bottom: 0.5px solid #3E3944;
    /* border-radius: 0px 0px 50px 50px; */
    opacity: 1;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
}

.create-dao-btn {
    padding: 15px 20px 15px 20px !important;
    /* background: transparent linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    color: #fff;
    border-radius: 50px;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    font-size: 15px;
}

.create-dao-btn i {
    color: #fff;
    font-weight: 600;
    font-size: 23px;
}

.create-individual-btn {
    padding: 15px 20px 15px 20px !important;
    /* background: transparent linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    color: #160f1e;
    border-radius: 50px;
    background: #ffc107;
    font-size: 15px;
}

.create-individual-btn i {
    color: #160f1e;
    font-weight: 600;
    font-size: 23px;
}

.connect-wallet-btn {
    padding: 10px 30px 10px 30px !important;
    /* background: transparent linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    color: #FFFFFF !important;
    font-size: 15px !important;
    /* background-color: #131627 !important; */
    height: unset !important;
    border-radius: 50px !important;
    background: #302A47 !important;
    height: 50px !important;
}

.connect-wallet-btn:hover {
    color: #186AB7;
}

.create-dao-btn:hover {
    color: #fff;
}

.card {
    border-radius: 30px;
}

.card .card-header {
    border-radius: 30px;
}

.user_avator {
    width: 50px;
    background: #302A47;
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
}

.form-label {
    color: #fff;
}

.tps {
    color: #a1a5b7;
    position: absolute;
    top: 7px;
    text-align: center;
    left: 42%;
    font-weight: 600;
}

@media only screen and (max-width: 768px) {
    .header {
        padding-top: 50px;
        padding-bottom: 35px;
    }
    .connect-wallet-btn {
        /* height: 45px !important;
        font-size: 12px !important;
        padding: 8px 16px 8px 16px !important; */
    }
    .tps {
        left: 30%;
        top: 5px;
    }
}

.float-container {
    position: fixed;
    bottom: 0%;
    right: 0;
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: flex-end;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 15px;
    padding: 7px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}

.float-container a {
    color: #fff;
}

#kt_content_container,
#kt_content {
    padding-bottom: 100px;
}

#menuToggle {
    display: block;
    position: fixed;
    top: 40px;
    left: 20px;
    z-index: 9999;
    -webkit-user-select: none;
    user-select: none;
}

#menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    /* hide this */
    z-index: 2;
    /* and place it over the hamburger */
    -webkit-touch-callout: none;
}


/*
 * Just a quick hamburger
 */

#menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #fff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}


/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */

#menuToggle input:checked~span {
    opacity: 1;
    display: block;
    transform: rotate(45deg) translate(-3px, 0px);
    background: #fff;
}


/*
 * But let's hide the middle one.
 */

#menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    /* display: none; */
    transform: rotate(0deg) scale(0.2, 0.2);
}


/*
 * Ohyeah and the last one should go the other direction
 */

#menuToggle input:checked~span:nth-last-child(2) {
    opacity: 1;
    transform: rotate(-45deg) translate(0, -2px);
}


/*
 * Make this absolute positioned
 * at the top left of the screen
 */

#menu {
    position: fixed;
    width: 90%;
    height: 200vh;
    padding-top: 20px;
    top: -15px;
    left: -25px;
    background: #181426;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    display: none;
    /* to stop flickering of text in safari */
    /* transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0); */
}

#menu li {
    padding: 14px 20px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #302A47;
    margin-bottom: 5px;
}

#menu li a {
    color: #fff;
    font-size: 17px;
    font-weight: 600;
}

#menu li a i {
    font-size: 20px;
    padding-right: 5px;
    color: #fff;
}


/*
 * And let's fade it in from the left
 */

#menuToggle input:checked~ul {
    /* transform: none; */
    display: block;
    opacity: 1;
}

.logo-mobile-li {
    margin-left: 55px;
}

#home_content_container {
    margin-top: 110px;
}

.search-container-raffle-detail {
    margin-top: 80px;
}

#kt_content_container,
.container-custom {
    margin-top: 110px;
}

.header {
    position: fixed;
    width: 100%;
}

@media screen and (max-width: 768px) {
    #menu {
        transform: none;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    }
}


/** Announcement Bar*/

a.emger:hover #top-header {
    background: #E6E6E6;
}

#header {
    background: none;
    padding-top: 0px;
    margin-top: 50px;
}

.em-standard {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.em-featured {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

@media (max-width: 500px) {
    .em-featured {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

#top-header {
    background: white;
    position: fixed;
    width: 100%;
    z-index: 9;
    left: 0;
    top: 80px;
    border-top: 0;
}

#emergency-response-opt {
    max-width: 1140px;
    margin: 0 auto;
    padding: 5px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 20px;
}

#emergency-response-opt .txt {
    display: flex;
    align-items: center;
}

#emergency-response-opt p {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 600;
    color: #181818;
    line-height: 20px;
    margin-right: 10px;
}

#emergency-response-opt p:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
}

#emergency-response-opt .emergency_header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 500px) {
    #emergency-response-opt .emergency_header {
        text-align: center;
    }
}

#emergency-response-opt img {
    width: 100%;
    height: auto;
}

#emergency-response-opt .emgergency-image {
    width: 35%;
    margin-right: 20px;
}

@media (max-width: 500px) {
    #emergency-response .emgergency-image {
        width: 100%;
        margin-bottom: 20px;
    }
}

#emergency-response-opt .txt.full {
    width: 65%;
}

@media (max-width: 500px) {
    #emergency-response .txt.full {
        width: 100%;
    }
}

#emergency-response-opt .btn {
    text-transform: uppercase;
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    display: inline-block;
    margin-top: 15px;
}

#emergency-response-opt .btn-donate {
    color: white;
    background: #ff1d34;
}

#emergency-response-opt .circle {
    margin-right: 15px;
}

#emergency-response-opt .circle .circle-wrapper {
    height: 12px;
    width: 12px;
    background: #0C884A;
    border-radius: 50px;
    display: block;
}

.pulsate-fwd {
    -webkit-animation: pulsate-fwd 1.5s ease-in-out infinite both;
    animation: pulsate-fwd 1.5s ease-in-out infinite both;
}


/* ----------------------------------------------
 * Generated by Animista on 2018-10-2 10:34:8
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */

@-webkit-keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(2);
        transform: scale(2);
    }
    100% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
    }
}

@keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-box-shadow: 0 0 0 0 rgba(12, 136, 74, 0.6);
    }
    50% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        -webkit-box-shadow: 0 0 0 10px rgba(12, 136, 74, 0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-box-shadow: 0 0 0 0 rgba(12, 136, 74, 0);
    }
}

.carousel-caption .btn-success {
    background: #0c884a;
}

@media (max-width: 767px) {
    .intro .jumbotron h1 {
        color: #e70052;
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    #header {
        margin-top: 70px;
    }
    #emergency-response-opt p {
        font-size: 15px;
    }
    #emergency-response-opt {
        padding: 10px;
    }
}

@media (max-width: 450px) {
    #emergency-response-opt p {
        font-size: 13px;
    }
    #emergency-response-opt {
        padding: 10px 15px;
    }
    #header {
        margin-top: 70px;
    }
}

@media (max-width: 320px) {
    #header {
        margin-top: 90px;
    }
}

.announcement-heading {
    font-weight: 600;
    color: #1e4395;
}