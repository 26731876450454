.filter-home {
    float: right;
    margin-bottom: 15px;
}

.overflow {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.border {
    border: 1px dashed #0095e8 !important;
}

.raffle-home-card {
    border: 1px solid #1e439526;
    height: auto !important;
}

.raffle-home-card .card-body {
    background: #fff !important;
    border-radius: 30px;
    /* border: 1px solid #3f4254; */
    padding: unset;
}

#hero_section {
    width: 100%;
    display: inline-block;
    /* background: #12101000 linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    background: #2a3042;
    /* height: 300px; */
    top: 0px;
    left: 0px;
    /* width: 1920px; */
    height: 300px;
    /* UI Properties */
    background: transparent url('../images/Rectangle 49.png') 0% 0% no-repeat padding-box;
    /* opacity: 0.14; */
}

.search-container-box {
    background-color: #181426 !important;
    margin: auto;
    width: 50%;
    border-radius: 50px !important;
    border: 1px solid #3f4254;
}

.card .card-header {
    background-color: #3f4254;
    border-color: unset;
}

.drop {
    background: #181426;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    min-height: 138px;
    max-height: 200px;
    overflow: scroll;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
    transition: .4s height;
    width: 43%;
    margin: auto;
    position: relative;
    top: -17px;
    text-align: left;
}

.search-input-container {
    /* padding-top: 26%; */
}

.drop li {
    font-size: 13px;
    padding: 10px 10px;
    list-style: none;
    border-top: 1px solid #3f4254;
    cursor: pointer;
}

.search-filer-img {
    width: 35px;
    margin-right: 10px;
    border-radius: 4px;
}

.heading-1 {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 50px;
    font-weight: 600;
}

.heading-2 {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 25px;
    font-weight: 600;
}

.flame-img {
    width: 40px;
    margin-left: 10px;
    /* height: 35px; */
}

.recent-raffle-text {
    font-size: 30px;
}

.raffle-img-on-card img {
    width: 100%;
    height: 255px;
    min-width: 100%;
    min-height: 255px;
    /* margin-top: 10px; */
    /* margin-bottom: 16px; */
    border-radius: 20px 20px 0px 0px;
    /* border-radius: 20px 20px 60px 60px; */
}

.collection-name {
    letter-spacing: 0px;
    color: #333;
    font-size: 15px;
    font-weight: 600;
    padding: 4px 10px;
}

.collection-img {
    width: 19px;
    height: 19px;
}

.raffle-name {
    padding-left: 10px;
    font-size: 20px;
    text-transform: capitalize;
    text-align: left;
    color: #000000;
    font-weight: 600;
}

.ticket-container {
    /* background: #25232E 0% 0% no-repeat padding-box; */
    /* border-radius: 20px; */
    /* opacity: 1; */
    /* backdrop-filter: blur(20px); */
    /* -webkit-backdrop-filter: blur(20px); */
    margin-left: 5px;
    /* text-align: center; */
}

.tickets-sold {
    width: 49%;
}

.ticket-container div {
    margin: 0 auto;
}

.ticket-sold {
    font-size: 17px;
    font-weight: 600;
    color: #1e4395;
}

.ticket-remain {
    font-size: 17px;
    font-weight: 600;
    color: #1e4395;
}

.ticket-price {
    font-size: 17px;
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #1e4395;
    /* width: 99px; */
}

.raffle-winner {
    font-size: 17px;
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #3a996b;
}

.ticket-price-2 {
    font-size: 17px;
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 5px;
    color: #1e4395;
}

.view-raffle-btn {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 25px;
    margin: 10px;
    color: #0852A8;
    /* border: 1px solid #0852A8 !important; */
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.view-raffle-btn:hover {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
}

.raffle-time {
    top: -20px;
    position: relative;
    background: transparent linear-gradient(180deg, #fec22a 0%, #fec22a 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(34px);
    width: 75%;
    margin: 0 auto;
    text-align: center !important;
    padding: 10px;
    color: #333;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.text-yellow {
    color: #FB9328;
}

.ticket-sold-label,
.ticket-price-label {
    color: #3f4254;
    font-size: 16px;
    font-weight: 500;
}

.verticle-line {
    border-bottom: 2px solid #3E3C46;
    width: 70%;
    margin: auto;
}

.float-right {
    float: right;
}

.sort-by-select {
    background-color: #181426 !important;
    border-color: #181426 !important;
    border-radius: 50px !important;
    font-size: 15px !important;
    padding: 10px 20px !important;
    width: 149px !important;
    color: #D9D9D9 !important;
}

.search-result-text {
    font-size: 20px;
}

.swal2-popup {
    background: #181426;
}

.swal2-popup .swal2-content,
.swal2-popup .swal2-html-container {
    font-size: 1.5rem !important;
    text-transform: capitalize;
    color: #fff;
}

.swal2-styled.swal2-confirm {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box !important;
    background-color: unset;
}

.desk-top-view {
    display: block;
    padding-bottom: 100px;
}

.mobile-view {
    display: none;
}

.header {
    display: flex;
}

@media only screen and (min-width: 768px) {
    .slick-next {
        right: 20px !important;
    }
}

@media only screen and (max-width: 768px) {
    /* .desk-top-view {
        display: none;
    }
    .mobile-view {
        display: block;
        font-size: 15px !important;
        text-align: center;
        margin-top: 150px;
    }
    .header {
        display: none;
    } */
    .search-container-box {
        margin-top: 20px;
        width: 100%;
        height: 70px;
    }
    .heading-1 {
        font-size: 30px;
    }
    .heading-2 {
        font-size: 16px;
    }
    .drop {
        width: 100%;
    }
    .featured-raffle-text {
        font-size: 35px !important;
    }
    .is-sticky {
        padding-bottom: 0px !important;
        top: 70px !important;
    }
    .home-tab {
        font-size: 13px !important;
    }
    .slick-next {
        right: 56px !important;
    }
    .slick-prev {
        left: 15px !important;
    }
}


/* .mobile-view img {
    width: 200px;
} */

.form-control {
    font-size: 1.4rem;
}

.dao-image-home {
    width: 45px;
    height: 45px;
    border-radius: 10px;
}

.dao-name-home {
    color: #fff;
    font-size: 17px;
    font-weight: 600;
}

.dao-raffle-count {
    color: #5e6278;
    font-size: 15px;
    font-weight: 600;
}

.dao-link-bg {
    background: #181426 !important;
}

.dao-table-row {
    border-bottom: 1px solid #3f4254 !important;
}

.dao-table-row td {
    border-bottom: 1px solid #3f4254;
}

.raffle__info {
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 0px 8px 0px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 7px;
    border-radius: 1em;
    background: #25232E 0% 0% no-repeat padding-box;
}

.plan__icon {
    height: 100%;
}

.raffle__detail {
    margin-left: 1.25em;
    text-align: start;
}

.raffle__title {
    color: #FB9328;
    font-size: 15px;
    font-weight: 500;
}

.raffle__text {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: unset;
}

.home-tab {
    font-weight: 600;
    font-size: 15px;
    color: #fff;
    border-radius: 25px;
    border: 1px solid;
    cursor: pointer;
}

.home-tab.active {
    background: #fec22a;
    color: #2e211f;
}

.text-blue {
    color: #1e4395;
}


/* .slick-prev {
    left: 60px !important;
} */

.slick-prev,
.slick-next {
    position: absolute;
    top: 45% !important;
    z-index: 2 !important;
}

.slick-prev:before,
.slick-next:before {
    font-size: 62px !important;
    opacity: unset !important;
    color: #ffc700 !important;
}

.slick-slide {
    padding: 10px;
}

.featured-raffle .raffle-time {
    width: unset;
}

.featured-raffle-text {
    font-size: 50px;
    color: #ffc107 !important;
    margin-left: 10px;
    font-weight: 600 !important;
}

.featured-raffle .collection-img {
    display: inline;
}

.lazy-loader .loader {
    position: relative;
    width: 80px;
    height: 80px;
    border: 4px solid #240229;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: -5px -5px 5px rgba(255, 255, 255, .1), 10px 10px 10px rgba(0, 0, 0, .4), inset -5px -5px 5px rgba(255, 255, 255, .2), inset 10px 10px 10px rgba(0, 0, 0, .4);
}

.lazy-loader .loader .text {
    /* content: "Loading"; */
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 25px;
    z-index: 2;
    background: #240229;
    border-radius: 50%;
    border: 2px solid #240229;
    box-shadow: inset -2px -2px 5px rgba(255, 255, 255, .2), inset 3px 3px 5px rgba(0, 0, 0, .5);
}

.lazy-loader .loader .text h6 {
    font-weight: 300;
    font-size: 0.9em;
    letter-spacing: 1px;
    animation: fade 1.5s ease-in-out infinite;
}

.lazy-loader .loader span {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
    animation: rotate .5s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.infinite-scroll-component {
    overflow: hidden !important;
}

.slick-dots li.slick-active button:before {
    color: #fff !important;
}

.slick-dots li button:before {
    color: #fff;
}

.spinner-container-v1 {
    /* width: 100%;
    height: 100%;
    z-index: 99999;
    text-align: center;
    background-color: #ffffff; */
    position: relative;
    text-align: center;
}

.spinner-v1 {
    margin: auto;
    border: 4px solid #dbf2ff;
    /*
  change to dotted for something cool.
  change width to 1px for tapered bar
  */
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    top: 45%;
    border-radius: 50%;
    border-right: 4px solid #018df7;
    text-align: center;
    animation-name: spin;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    /*animation-timing-function: linear; /*linear spin */
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    /*50% {width:24px;height:24px} /*shrinking effect*/
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    /*50% {width:24px;height:24px} /*shrinking effect*/
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.is-sticky {
    position: fixed;
    top: 60px;
    /* left: 0; */
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #0a0911 !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 10px;
    /* padding-left: 8%; */
}

.back-to-top-button {
    display: inline-block;
    background-color: #ffc107;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 30px;
    position: fixed;
    bottom: 115px;
    right: 30px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    /* opacity: 0; */
    /* visibility: hidden; */
    z-index: 1000;
}

.back-to-top-button::after {
    content: "\F13A";
    font-family: 'bootstrap-icons';
    font-weight: normal;
    font-style: normal;
    font-size: 2em;
    line-height: 50px;
    color: #2e211f;
}

.back-to-top-button:hover {
    cursor: pointer;
    background-color: #333;
}

.back-to-top-button:hover::after {
    color: #fff;
}

.back-to-top-button:active {
    background-color: #555;
}

.winner {
    border: 5px solid #ffc700 !important;
    background: #fff;
}

.winner .view-raffle-btn {
    background: transparent linear-gradient(180deg, #ffc700 0%, #ffc700 100%) 0% 0% no-repeat padding-box;
    color: #160f1e;
}

.winner .view-raffle-btn i {
    color: #160f1e;
    font-size: 19px;
}


/* .featured-row {
    margin-left: 35px;
    margin-right: 35px;
} */

.infinite-scroll-component__outerdiv {
    margin-left: 10px;
    margin-right: 10px;
}